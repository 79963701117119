 .Metaverse:hover {
   border: 1px solid grey;
   box-shadow: 3px 3px 5px rgba(135, 132, 132, 0.3), inset 3px 3px 5px rgba(135, 132, 132, 0.3);
 }

 .Metaverse {
   border: none;
   box-shadow: none;
   padding: 30px;
   border-radius: 10px;
 }


 .GuidelineTable {
   margin-top: 80px;
 }


 .tableTopic {
   font-weight: 700;
   margin-bottom: 20px;
   text-align: left;
   width: 50vw;
 }

 .tableTopic2 {
   font-weight: 700;
   text-align: left;
   margin-bottom: 10px;
   text-decoration: underline;
 }

 .tableContent {
   text-align: left;

 }

 .tableNum {
   font-weight: 600;
 }


 .contactUs {
   color: #22543d;
   text-decoration: underline;
 }


 .smallNum {
   display: inline-block;
   font-size: smaller;
   position: relative;
   top: -3px;
 }

 .question {
   font-weight: 800;
   font-size: larger;
 }

 .termsAndConditions {
   font-size: small;
 }

 .contact {
   margin-bottom: 20px;
 }

 .tablePic {
   display: inline;
   width: 5%;
   height: 5%;
   position: relative;
   top: 8px;
 }

 @media (max-width: 767px) {
   .container {
     width: 60%;
   }
 }

 @media (max-width: 480px) {
  html {
    font-size: 75%;
  }
}
