.Metaverse {
    border: none;
    box-shadow: none;
    padding: 30px;
    border-radius: 10px;
}



.joinButton {
    margin-bottom: 60px;
}

.coListTitle {
    font-size: 22px;
    text-decoration: underline;
    text-align: center;
    font-weight: 500;
}

.companyListBox {
    width: 70vw;
}

.companyListCenter {
    flex-wrap: wrap;
}

.companyImgBox1 {
    width: 250px;
    height: 250px;
    margin: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: none;
    padding: 25px;
    border-radius: 10px;
}


.companyImgBox1:hover {
    border: 1px solid grey;
    box-shadow: 3px 3px 5px rgba(135, 132, 132, 0.3), inset 3px 3px 5px rgba(135, 132, 132, 0.3);
}


.termsAndConditions {
    font-size: small;
}

.contactUs {
    color: #22543d;
    text-decoration: underline;
}

.question {
    font-weight: 800;
    font-size: larger;
}

.comingSoon {
    font-size: 20px;
    color: black;
    margin-top: 80px;
    margin-bottom: 80px;
}