.title {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10vh;
    margin-bottom: 5vh;
}

.content {
    font-size: 18px;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-bottom: 5vh;
    font-family: ff2;
}

.contentTitle {
    font-size: 18px;
    font-weight: 900;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-bottom: 5vh;
    font-family: ff2;
}

.underLine {
    border-bottom: 1px solid black;
}


.contentEn {
    font-size: 18px;
    margin-left: 15vw;
    margin-right: 15vw;
    margin-bottom: 5vh;
    margin-top: 0px;
    font-family: ff2;
    font-style: normal;
    font-weight: 500;
}

.contentHead {
    font-weight: 900;
}

.contentOrderList {
    font-size: 18px;
    font-family: ff2;
    font-style: normal;
    font-weight: 500;
}

.noMarginBottom {
    margin-bottom: 0px;
}